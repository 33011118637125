
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container h5 {
  font-weight: 400;
  align-self: flex-start;
}

.header {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.logo h2 {
  margin: 0;
  font-size: 60px;
}

.logo-icon {
  height: 50px;
}

.btc-price {
  margin: 0;
  display: flex;
  gap: 1rem;
}

.btc-price h4 {
  display: flex;
  align-items: center;
}

h5 {
  margin: 10px;
  margin-top: 20px;
}

.input-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price-input-line {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-line {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.total {
  display: flex;
}

.btc-total {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.btc-total p {
  font-weight: bold;
}

.profit-total-line {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.profit-total-line p {
  font-weight: bold;
}

.target-price {
  display: flex;
  font-weight: bold;
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  .logo h2 {
    font-size: 40px;
  }

  .logo-icon {
    height: 30px;
  }

  .price-input-line {
    flex-direction: column;
  }

  .total-line {
    flex-direction: column;
  }

  .profit-total-line {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}